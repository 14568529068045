



































































































































































































































































































































































































































































.CertificationBox {
  display: flex;
  .certificationTitle {
    font-weight: bolder;
  }
  .certifictionImg {
    padding-left: 20px;
  }
  .el-image {
    width: 210px;
    height: 230px;
  }
}
.failTitle {
  font-weight: bolder;
  padding: 20px 0;
}
.shells {
  height: 200px;
  .shell {
    width: 117px;
    height: 100px;
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .el-checkbox {
      position: absolute;
      right: 0;
      top: -1px;
    }
  }
}
.pageNum {
  margin-top: 10px;
}
